/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from 'react';
import { MenuItem, Button, TextField } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useLoginMenu from "../../../hooks/use-login-menu";


const LoginMenu = React.forwardRef((props, ref) => {
    const { authenticate, canLogin, updatePassword } = useLoginMenu(props);

    return (
        <MenuItem>
            <form css={css`text-align:center;`} noValidate autoComplete="off">
                <h4>Login to manage</h4>
                <br />
                <TextField id="admin-pwd-field"
                    css={css`display:block;`}
                    type="password"
                    label="Admin password"
                    variant="outlined"
                    autoComplete="off"
                    required
                    onChange={updatePassword} />
                <br />
                <Button variant="outlined" type="submit" onClick={authenticate} disabled={!canLogin}>
                    Enter&nbsp;
                    <ExitToAppIcon />
                </Button>
            </form>
        </MenuItem>);
});

export default LoginMenu;