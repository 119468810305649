import React, { createContext, useState, useEffect, useCallback } from "react";
import api from "../services/use-api";

export const LocalContext = createContext();


const LocalProvider = ({ children }) => {
    const [isLocal, setIsLocal] = useState(false);
    const { getData, showResponse } = api();

    const getIsLocal = useCallback(async () => {
        await getData(`${process.env.PUBLIC_URL}/local-check`)
            .then(response => setIsLocal(response.data))
            .catch(ex => showResponse(ex));
    }, [getData, showResponse]);

    useEffect(() => {
        console.log(`LocalProvider: ${isLocal}`);
        getIsLocal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <LocalContext.Provider value={{ isLocal, setIsLocal }}>
            {children}
        </LocalContext.Provider>
    );
};

export { LocalProvider };