import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Hidden from '@material-ui/core/Hidden';
import DeleteButton from "./delete-button";
import LookupElements from "./lookup-elements";

const CommonRow = ({ row, removeRow, open, setOpen }) => {

    return (
        <TableRow>
            <Hidden xlUp>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </Hidden>
            <TableCell component="th" scope="row">
                {row.serialNumber}
            </TableCell>
            <Hidden smDown>
                <TableCell align="right">{row.version}</TableCell>
            </Hidden>
            <Hidden lgDown>
                <TableCell align="right">{row.oem}</TableCell>
                <TableCell align="right">{row.type}</TableCell>
            </Hidden>
            <Hidden mdDown>
                <TableCell align="right">{row.voltage}</TableCell>
                <TableCell align="right">{row.boudrate}</TableCell>
            </Hidden>
            <Hidden xsDown>
                <TableCell align="right">
                    <LookupElements row={row}></LookupElements>
                </TableCell>
            </Hidden>
            <TableCell align="right">
                <DeleteButton row={row} removeRow={removeRow}></DeleteButton>
            </TableCell>
        </TableRow>
    );
};

export default CommonRow;