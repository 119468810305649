import { ContextKeys } from "../../conventions/constants";

const AlertReducer = (state, action) => {
    switch (action.type) {
        case ContextKeys.SetAlert:
            return action.payload;
        case ContextKeys.RemoveAlert:
            return null;
        default:
            return state;
    }
};

export default AlertReducer;
