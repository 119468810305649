/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useAlertInfo from "../../hooks/use-alert-info";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const TransitionRight = props => <Slide {...props} direction="right" />;

const SnackbarItem = ({
    alert: { msg, type, duration },
    open,
    handleClose,
    handleExited,
}) => {
    const { getIcon, getIconColor } = useAlertInfo();

    const classesMaterial = makeStyles(() => ({
        success: { backgroundColor: getIconColor("success"), },
        critical: { backgroundColor: getIconColor("error"), },
        error: { backgroundColor: getIconColor("error"), },
        warning: { backgroundColor: getIconColor("warning"), },
        info: { backgroundColor: getIconColor("info"), },
    }))();

    const Icon = getIcon(type, true);

    return (
        <Snackbar
            ContentProps={{ classes: { root: classesMaterial[type] } }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            TransitionComponent={TransitionRight}
            open={open}
            disableWindowBlurListener={true}
            autoHideDuration={duration || 6000}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExited }}
            message={
                <span id="message-id" css={css` display: flex;  align-items: center; word-break: break-word; `} >
                    <Icon css={css` font-size: 20px; opacity: 0.9; margin-right: 8px; `} />
                    {msg}
                </span>
            }
            action={[
                <IconButton key="close" color="inherit" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    );
};

SnackbarItem.propTypes = {
    alert: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleExited: PropTypes.func.isRequired,
};

export default SnackbarItem;
