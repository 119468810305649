import React from "react";
import SnackbarItem from "./snackbar-item";
import useAlert from "../../hooks/use-alert";

const Alert = () => {

    const { alertData,
        open,
        handleClose,
        handleExited } = useAlert();

    return (
        <SnackbarItem
            alert={alertData}
            open={open}
            handleClose={handleClose}
            handleExited={handleExited}
        />
    );
};

export default Alert;
