import React, { createContext, useState } from "react";

export const FileUploadContext = createContext();

const FileUploadProvider = ({ children }) => {
    const [fileUploaded, setFileUploaded] = useState(false);

    return (
        <FileUploadContext.Provider value={{ fileUploaded, setFileUploaded }}>
            {children}
        </FileUploadContext.Provider>
    );
};

export default FileUploadProvider;

