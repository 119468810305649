import { useState, useEffect } from 'react';
import useApi from "../services/use-api";
import { PwdRegex } from "../conventions/constants";

const useLoginMenu = (props) => {
    const [password, setPassword] = useState("");
    const [canLogin, setCanLogin] = useState(false);
    const { postData, showResponse } = useApi();

    useEffect(() => {
        setCanLogin(isValidPwd(password));
    }, [password]);

    const authenticate = async (event) => {
        event.preventDefault();
        await postData(`${process.env.PUBLIC_URL}/auth`, { Password: password })
            .then(response => props.onAuthenticate(response.data))
            .catch(ex => showResponse(ex));
    }

    const isValidPwd = (pwd) => PwdRegex.test(pwd);

    const updatePassword = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
    };

    return ({ authenticate, canLogin, updatePassword });
};

export default useLoginMenu;