
import axios from "axios";

const FileDownload = (sid) => {

    const download = (url, fileName) => axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            "SID": sid
        },
    }).then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });

    return {
        download
    };

};

export default FileDownload;