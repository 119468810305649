import { useContext, useState } from 'react';
import { FileUploadContext } from "../contexts/file-upload-context";
import useApi from "../services/use-api";
import AlertContext from '../contexts/alert/alert-context';


const useUploadControl = () => {
    const { setFileUploaded } = useContext(FileUploadContext);
    const { setAlert } = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const { postData, showResponse } = useApi();

    const browseFile = () => {
        document.getElementById("newSourceFile").click();
    }

    const onBrowseFile = () => {
        var element = document.getElementById("newSourceFile");
        if (element.files && element.files.length > 0) {
            setLoading(true);
            let file = element.files[0];
            let formData = new FormData();
            formData.append('name', file.name);
            formData.append('file_attachment', file);

            postData(`${process.env.PUBLIC_URL}/file-upload`, formData)
                .then(response => {
                    const errors = response.data.errors || 0;
                    const message = `Entries created: ${response.data.entries || 0},
Errors/overrides ocurred: ${errors}.
To view uploaded data use URL: ${window.location.href}test`
                    setAlert(message, errors ? "warning" : "success", 60000);
                    setFileUploaded(true);
                }).catch(ex => {
                    showResponse(ex);
                }).finally(() => {
                    formData = null;
                    document.getElementById("newSourceFile").value = "";
                    setLoading(false);
                });
        }
    }


    return ({
        loading,
        setLoading,
        browseFile,
        onBrowseFile
    });
};

export default useUploadControl;