import { useContext, useState, useRef, useEffect } from 'react';
import { AuthContext } from "../contexts/auth-context";
import AlertContext from "../contexts/alert/alert-context";
import useApi from '../services/use-api';

const minTimeoutCycle = 30000;

const usePersonalMenuContent = (isAuthenticated) => {
    const [anchorEl, setAnchorEl] = useState(null);
    let isMenuOpen = Boolean(!!anchorEl);
    const [openDialog, setOpenDialog] = useState(false);

    const { setSid } = useContext(AuthContext);
    const { setAlert } = useContext(AlertContext);
    const { getData } = useApi();


    let timer = useRef();

    const checkIsAuthenticated = () => {
        return getData(`${process.env.PUBLIC_URL}/auth`)
            .then(response => {
                window.clearTimeout(timer.current);

                if (isAuthenticated && !response.data) {
                    window.localStorage.clear();
                    return window.location.reload();
                }

                timer.current = window.setTimeout(() => checkIsAuthenticated(), minTimeoutCycle * 2);
                console.log(`checkIsAuthenticated -> isAuthenticated: ${isAuthenticated},  response: ${response.data}`);
            });
    };

    useEffect(() => {
        timer.current = window.setTimeout(() => checkIsAuthenticated(), minTimeoutCycle);
        return () => window.clearTimeout(timer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);


    const handleAuthenticateResult = (success) => {
        setSid(success)
        if (success) {
            setAnchorEl(null);
        }
        setAlert(success ? "Login succeeded" : "Login failed", success ? "success" : "warning", 6000);
    };


    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const showLogoutConfirmation = () => {
        setOpenDialog(true);
    }

    return ({
        anchorEl,
        setAnchorEl,
        isMenuOpen,
        openDialog,
        setOpenDialog,
        handleAuthenticateResult,
        handleProfileMenuOpen,
        handleMenuClose,
        showLogoutConfirmation
    });
};

export default usePersonalMenuContent;