import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import CommonRow from "./common-row";
import ExpandableRow from "./expandable-row";


const Row = ({ row, removeRow }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <CommonRow row={row} removeRow={removeRow} open={open} setOpen={setOpen} />
            <Hidden xlUp>
                <ExpandableRow row={row} open={open} />
            </Hidden>
        </React.Fragment>
    );
};

export default Row;