import React, { createContext, useState, useEffect, useCallback } from "react";
import { StorageKeys } from "../conventions/constants";
import LogOut from "../services/log-out";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const sidInStorage = localStorage.getItem(StorageKeys.Sid);
    const [sid, setSid] = useState(sidInStorage && sidInStorage !== "false" ? sidInStorage : false);
    const { execute: logoutUser } = LogOut();

    const onSidChange = useCallback(() => {
        console.log(`AuthProvider.onSidChange:${sid}`);
        localStorage.setItem(StorageKeys.Sid, sid);
        if (!sid) {
            logoutUser(sid);
        }
    }, [logoutUser, sid]);

    useEffect(() => onSidChange(), [onSidChange, sid]);

    return (
        <AuthContext.Provider value={{ sid, setSid }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };

