/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Colors } from "../../../conventions/constants"
import DownloadControl from "./download-control";
import UploadControl from "./upload-control";
import withAuth from "../../../hooks/with-auth";


const FileManagement = ({ isAuthenticated }) => {

    return (
        isAuthenticated ?
            <fieldset css={css`display: inline-flex;padding: 5px 20px 10px;margin:-10px 10px 10px;border-radius:5px; border:thin solid ${Colors.Silver}`}>
                <legend>File management</legend>
                <div css={css`display:inline-grid;grid-template-columns:auto auto`}>
                    <DownloadControl />
                    <UploadControl />
                </div>
            </fieldset> : null);
};

export default withAuth(FileManagement);