/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import LookupElements from "./lookup-elements";


const useRowStyles = makeStyles({
    cellLeft: {
        textAlign: 'left !important',
    },
});

const ExpandableRow = ({ row, open }) => {
    const classes = useRowStyles();

    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1} css={css`float:left;`}>
                        <Table size="small" css={css`float:left;min-width:350px;width:auto`}>
                            <TableBody>
                                <Hidden mdUp>
                                    <TableRow key={`${row.serialNumber}_version`}>
                                        <TableCell className={classes.cellLeft}>VERSION:</TableCell>
                                        <TableCell className={classes.cellLeft}>{row.version}</TableCell>
                                    </TableRow>
                                </Hidden>
                                <TableRow key={`${row.serialNumber}_oem`}>
                                    <TableCell className={classes.cellLeft}>OEM:</TableCell>
                                    <TableCell className={classes.cellLeft}>{row.oem}</TableCell>
                                </TableRow>
                                <TableRow key={`${row.serialNumber}_type`}>
                                    <TableCell className={classes.cellLeft}>TYPE:</TableCell>
                                    <TableCell className={classes.cellLeft}>{row.type}</TableCell>
                                </TableRow>
                                <Hidden lgUp>
                                    <TableRow key={`${row.serialNumber}_voltage`}>
                                        <TableCell className={classes.cellLeft}>VOLTAGE:</TableCell>
                                        <TableCell className={classes.cellLeft}>{row.voltage}</TableCell>
                                    </TableRow>
                                    <TableRow key={`${row.serialNumber}_boudrate`}>
                                        <TableCell className={classes.cellLeft}>BOUDRATE:</TableCell>
                                        <TableCell className={classes.cellLeft}>{row.boudrate}</TableCell>
                                    </TableRow>
                                </Hidden>
                                <Hidden smUp>
                                    <TableRow key={`${row.serialNumber}_remoteDownload`}>
                                        <TableCell className={classes.cellLeft}>REMOTE DOWNLOAD:</TableCell>
                                        <TableCell className={classes.cellLeft}>
                                            <LookupElements row={row}></LookupElements>
                                        </TableCell>
                                    </TableRow>
                                </Hidden>
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default ExpandableRow;