import React, { useReducer } from "react";
import AlertContext from "./alert-context";
import AlertReducer from "./alert-reducer";
import { ContextKeys } from "../../conventions/constants";

const AlertState = ({ children }) => {
    const initialState = { msg: null, type: null, duration: 6000 };

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = (msg, type, duration) =>
        dispatch({
            type: ContextKeys.SetAlert,
            payload: { msg, type, duration: duration||6000 },
        });

    const removeAlert = () =>
        dispatch({
            type: ContextKeys.RemoveAlert,
        });

    return (
        <AlertContext.Provider
            value={{
                alert: state,
                setAlert,
                removeAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertState;
