import axios from "axios";

const LogOut = () => {

    const execute = (sid) => sid ?
        axios.create({ headers: { "SID": sid } })
            .post(`${process.env.PUBLIC_URL}/logout`) :
        Promise.resolve();

    return {
        execute,
    };
};

export default LogOut;
