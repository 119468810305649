/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import FooterAnchor from "./footer-anchor";


const Footer = () => {
    return (
        <footer className="tlt-footer" css={css`width:100%`}>
            <div className="tlt-container">
                <nav className="tlt-sitemap">
                    <ul className="tlt-section">
                        <h4 className="tlt-links-title">Products</h4>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/products/vehicle-tracking/">Vehicle tracking</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/products/autonomous-trackers/">Personal tracking</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/products/networking/">Networking</a></li>
                    </ul>

                    <ul className="tlt-section">
                        <h4 className="tlt-links-title">Manufacturing</h4>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/odm-oem/">ODM Service</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/production-capabilities/">Production capabilities</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/sourcing-management/">Sourcing Management</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/quality/">Quality</a></li>
                    </ul>

                    <ul className="tlt-section">
                        <h4 className="tlt-links-title">Support</h4>
                        <li><a target="_blank" href="https://wiki.teltonika.lt" rel="noreferrer">Wiki knowledge Base</a></li>
                        <li><a target="_blank" href="https://community.teltonika.lt" rel="noreferrer">Crowd Support Forum</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/warranty-repair/">Warranty &amp; Repair</a></li>
                    </ul>

                    <ul className="tlt-section">
                        <h4 className="tlt-links-title">Social activity</h4>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/iot-academy/">IoT academy</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/b2b-academy/">B2B academy</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/social-activity/volunteering-and-giving/">Volunteering and giving</a></li>
                    </ul>

                    <ul className="tlt-section">
                        <h4 className="tlt-links-title">Newsroom</h4>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/news-room/company-news/">Company news</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/news-room/product-news/">Products news</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/news-room/events/">Events</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/news-room/awards/">Awards</a></li>
                    </ul>

                    <ul className="tlt-section">
                        <h4 className="tlt-links-title">About us</h4>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/vision-and-values/">Vision and values</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/about-us/career/">Career</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/company/contacts/">Contacts</a></li>
                    </ul>
                </nav>

                <div className="subfooter">
                    <div className="policies">
                        <ul>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/privacy/">Privacy</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/cookies/">Cookies</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://teltonika.lt/eol/">EOL Policy</a></li>
                        </ul>
                    </div>
                </div>

                <div className="tlt-footer-bottom">
                    <small className="tlt-copyright">Copyright © 2019, Teltonika. All rights reserved</small>
                    <div className="tlt-social">
                        <h4 className="tlt-links-title">
                            Connect
                        </h4>
                        <FooterAnchor href={"https://www.facebook.com/TELTONIKAinternational/"} src={"img/fb.svg"} alt={"facebook"} />
                        <FooterAnchor href={"https://www.linkedin.com/company/teltonika"} src={"img/linkedin.svg"} alt={"linkedin"} />
                        <FooterAnchor href={"https://twitter.com/teltonika"} src={"img/twitter.svg"} alt={"twitter"} />
                        <FooterAnchor href={"https://www.youtube.com/channel/UCjWIn5amqvtWc7X7tI_04LQ"} src={"img/youtube.svg"} alt={"youtube"} />
                        <FooterAnchor href={"https://www.instagram.com/teltonika_official/"} src={"img/instagram.svg"} alt={"instagram"} />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;