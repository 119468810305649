/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Colors } from "../../../conventions/constants";
import useDownloadControl from "../../../hooks/use-download-control";
import withAuth from "../../../hooks/with-auth";

const DownloadControl = ({ sid, isAuthenticated }) => {

    const {
        anchorRef,
        fileList,
        selectedIndex,
        open,
        handleClick,
        handleMenuItemClick,
        handleClose,
        handleToggle
    } = useDownloadControl(sid, isAuthenticated);

    const ariaControls = open ? 'split-button-menu' : undefined;
    const ariaExpanded = open ? 'true' : undefined;

    return (
        isAuthenticated ?
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
                        <Button onClick={handleClick}
                            css={css`&:hover{text-decoration:underline;color:${Colors.TeltonikaBlue}}`}
                            title="Click to download file">
                            <GetAppIcon css={css`font-size:1.2rem`} />
                            {fileList[selectedIndex]}
                        </Button>
                        <Button
                            size="small"
                            aria-controls={ariaControls}
                            aria-expanded={ariaExpanded}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            title="Select file to download">
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {fileList.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid> : null);
};

export default withAuth(DownloadControl);