/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Colors } from "../../../conventions/constants";

const LookupElements = ({ row }) => {
    return (
        (row.remoteTachoDownload || [])
            .map((x, i) => x.link ?
                <a
                    key={`a-${i}-${row.serialNumber}`}
                    href={x.link}
                    title={x.tooltip || x.text}
                    css={css`color:${Colors.TeltonikaBlue};display:block;`}
                    target="_blank" rel="noopener noreferrer">
                    {x.text}
                </a> :
                <span
                    key={`s-${i}-${row.serialNumber}`}
                    css={css`display:block;`}
                    title={x.tooltip || x.text}>
                    {x.text}
                </span>));
};

export default LookupElements;