

import SuccessIcon from "@material-ui/icons/CheckCircle";
import SuccessIconOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import CriticalError from "@material-ui/icons/Cancel";
import CriticalErrorIconOutlined from "@material-ui/icons/CancelOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import ErrorIconOutlined from "@material-ui/icons/ErrorOutlineOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import WarningIconOutlined from "@material-ui/icons/ReportProblemOutlined";
import InfoIcon from "@material-ui/icons/Info";
import InfoIconOutlined from "@material-ui/icons/InfoOutlined";

const useAlertInfo = () => {
    const colors = {
        success: "#007E33",
        error: "#CC0000",
        warning: "#FFA000",
        info: "#2196f3"
    };

    const levels = {
        0: { id: "critical", name: "Critical error" },
        1: { id: "error", name: "Error" },
        2: { id: "warning", name: "Warning" },
        3: { id: "info", name: "Information" },
    };

    const icons = {
        success: {
            icon: { filled: SuccessIcon, outlined: SuccessIconOutlined },
            color: colors.success,
        },
        critical: {
            icon: {
                filled: CriticalError,
                outlined: CriticalErrorIconOutlined,
            },
            color: colors.error,
        },
        error: {
            icon: { filled: ErrorIcon, outlined: ErrorIconOutlined },
            color: colors.error,
        },
        warning: {
            icon: { filled: WarningIcon, outlined: WarningIconOutlined },
            color: colors.warning,
        },
        info: {
            icon: { filled: InfoIcon, outlined: InfoIconOutlined },
            color: colors.info,
        },
    };

    const getTypeId = index => levels[index].id;

    const getTypeName = index => levels[index].name;

    const getIcon = (name, filled = false) => {
        const filedOrOutlined = filled ? "filled" : "outlined";
        return icons[name] ? icons[name].icon[filedOrOutlined] : icons.info.icon[filedOrOutlined];
    };
    
    const getIconColor = name => icons[name].color;

    return { getTypeId, getTypeName, getIcon, getIconColor };
};

export default useAlertInfo;
