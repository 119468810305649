import React, { useContext } from "react";
import { AuthContext } from "../contexts/auth-context";
import { LocalContext } from "../contexts/local-context";

const withAuth = (Component) => {
    return (props) => {
        const { sid } = useContext(AuthContext);
        const { isLocal } = useContext(LocalContext);
        const isAuthenticated = sid && sid !== "false" && isLocal;

        return (<Component isLocal={isLocal} sid={sid} isAuthenticated={isAuthenticated} {...props}></Component>);
    };
};

export default withAuth;