import { useContext } from "react";
import axios from "axios";
import AlertContext from "../contexts/alert/alert-context";
import { StorageKeys } from "../conventions/constants";
import { AuthContext } from "../contexts/auth-context";


const useApi = () => {
    const { setAlert } = useContext(AlertContext);
    const { sid } = useContext(AuthContext);

    const CancelToken = axios.CancelToken;
    const cancelSource = CancelToken.source();
    const isCanceled = axios.isCancel;

    const getSid = () => {
        return sid !== "false" ? (sid || localStorage.getItem(StorageKeys.Sid)) : sid;
    };

    const api = axios.create({
        cancelToken: cancelSource.token,
        headers: {
            "Content-Type": "application/json",
            "SID": getSid()
        },
    });

    const getData = resource => {
        console.log(`API.getData: ${resource}`);
        return api.get(resource);
    };

    const postData = (resource, payload) => {
        console.log(`API.postData: ${resource}`);
        return api.post(resource, payload);
    };

    const getErrorMessageFromData = (error, errorData) => Array.isArray(errorData) ? errorData.join(". ") : (error.message || errorData);


    const getErrorMessage = (error) => {
        const errorData = error.response && error.response.data;

        if (!errorData) {
            return error.response ? error.response.statusText : error.message;
        }

        if (errorData.title) {
            return errorData.title;
        }

        return getErrorMessageFromData(error, errorData);
    };

    const showResponse = error => {
        console.log(`API.showResponse()`);

        const status = error.response ? error.response.status : error.status;

        if (status === 401) {
            setAlert("Unauthorized connection to API", "error", 6000);
            window.localStorage.clear();
            return window.location.reload();
        }

        const message = getErrorMessage(error);
        setAlert(message, "error", 6000);
    };

    return {
        api,
        cancelSource,
        isCanceled,
        getData,
        postData,
        showResponse,
    };
};

export default useApi;
