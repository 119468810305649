export const ContextKeys = {
    SetAlert: "SetAlert",
    RemoveAlert: "RemoveAlert",    
}
export const StorageKeys = {
    Sid: "SID"
}
export const Colors = {
    TeltonikaBlue: "#0054A6",
    GrayBorder: "#e0e4ea",
    GrayText: "#808080",
    SwampColor: "#3d4c61",
    White: "rgb(255,255,255)",
    Smooth: "rgb(73, 73, 73)",
    Silver: "#CCCCCC"
};

export const PwdRegex=/^.*[^\s]{6,30}$/;
