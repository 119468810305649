import React, { useContext, useEffect, useState, useCallback } from 'react';
import { FileUploadContext } from "../contexts/file-upload-context";
import useApi from "../services/use-api";
import FileDownload from "../services/file-download";


const useDownloadControl = (sid, isAuthenticated) => {
    const { fileUploaded, setFileUploaded } = useContext(FileUploadContext);
    const [fileList, setFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");
    const { getData, showResponse } = useApi();
    const { download } = FileDownload(sid);

    const getList = useCallback(async () => {
        if (isAuthenticated) {
            await getData(`${process.env.PUBLIC_URL}/list-files`)
                .then(response => setFileList(response.data))
                .catch(ex => showResponse(ex));
        } else {
            Promise.resolve();
        }
    }, [getData, showResponse, isAuthenticated]);

    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedFile) {
            const fileUrl = `${process.env.PUBLIC_URL}/download?fileName=${selectedFile}`;
            download(fileUrl, selectedFile)
                .finally(() => setSelectedFile(null));
        }
    }, [download, selectedFile]);

    useEffect(() => {
        console.log(`DownloadControl.fileUploaded: ${fileUploaded}`)
        if (fileUploaded) {
            getList();
            setFileUploaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUploaded, setFileUploaded]);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleClick = () => {
        setSelectedFile(fileList[selectedIndex]);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return ({
        anchorRef,
        fileList,
        selectedIndex,
        open,
        handleClick,
        handleMenuItemClick,
        handleClose,
        handleToggle
    })
};

export default useDownloadControl;

