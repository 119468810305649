/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../../contexts/auth-context";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { Colors } from "../../../conventions/constants";


const LogoutConfirmation = ({ open, close }) => {
    const { setSid } = useContext(AuthContext);


    const logoutUser = () => {
        close();
        setSid(false);
    }

    return (
        <Fragment>
            <Dialog open={open} onClose={close}>
                <DialogTitle>{"Are you sure you want to log out?"}</DialogTitle>
                <DialogActions>
                    <Button disableRipple variant="outlined" onClick={close}>
                        Cancel
                    </Button>
                    <Button disableRipple variant="outlined"
                        css={css`background:${Colors.TeltonikaBlue}; color:${Colors.White};
                            &:hover{background:${Colors.TeltonikaBlue}; opacity:.9;}`}
                        onClick={logoutUser} autoFocus>
                        Log out
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

LogoutConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
};

export default LogoutConfirmation;
