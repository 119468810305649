import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, } from "react-router-dom";
import Alert from "../components/alert/index";
import Main from "../components/main"

const Routes = () => {
    return (

        <Router>
            <Alert />
            <Switch>
                <Route exact path="/" component={Main} />
                <Redirect to="/" />
            </Switch>
        </Router>
    );
};

export default Routes;
