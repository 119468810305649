import { useContext, useState, useEffect } from "react";
import AlertContext from "../contexts/alert/alert-context";

const useAlert = () => {
    const { alert, removeAlert } = useContext(AlertContext);
    const [open, setOpen] = useState(false);
    const [snackPack, setSnackPack] = useState([]);
    const [alertData, setAlertData] = useState(alert || { msg: null, type: null, duration: 6000 });

    useEffect(() => {
        alert && alert.msg !== null
            ? setSnackPack([...snackPack, alert])
            : setOpen(false);
        // eslint-disable-next-line
    }, [alert]);

    useEffect(() => {
        if (snackPack.length > 0) {
            if (!alert || !alertData.msg) {
                setAlertData(snackPack.shift());
                setOpen(true);
            } else if (alertData.msg && open) {
                setOpen(false);
            }
        }
        // eslint-disable-next-line
    }, [snackPack, alertData, open]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") return;

        removeAlert();
    };

    const handleExited = () => setAlertData({ type: null, msg: null, duration: 6000 });

    return ({
        alertData,
        open,
        handleClose,
        handleExited
    });
};

export default useAlert;