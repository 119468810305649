/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { AccountCircle } from '@material-ui/icons';
import { Menu, IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LoginMenu from "./login-menu";
import PasswordChangeMenu from "./password-change-menu";
import LogoutConfirmation from "./logout-confirmation";

import usePersonalMenuContent from "../../../hooks/use-personal-menu-content";
import withAuth from "../../../hooks/with-auth";


const PersonalMenuContent = ({ isAuthenticated }) => {
    const {
        anchorEl,
        setAnchorEl,
        isMenuOpen,
        openDialog,
        setOpenDialog,
        handleAuthenticateResult,
        handleProfileMenuOpen,
        handleMenuClose,
        showLogoutConfirmation
    } = usePersonalMenuContent(isAuthenticated);

    return (
        <div edge="end">
            <IconButton css={css`padding-top: 0;`} disableRipple onClick={handleProfileMenuOpen} color="inherit" >
                <AccountCircle />
            </IconButton>
            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                {!isAuthenticated ?
                    <LoginMenu onAuthenticate={handleAuthenticateResult} /> :
                    <PasswordChangeMenu setAnchorEl={setAnchorEl} />}
            </Menu>
            {isAuthenticated ? <IconButton
                css={css`padding-top: 0;`}
                disableRipple
                onClick={showLogoutConfirmation}
                color="inherit"
                autoFocus
                title="Log Out"
            >
                <ExitToAppIcon />
            </IconButton> : null}
            <LogoutConfirmation open={openDialog} close={() => setOpenDialog(false)} />
        </div>
    );
};

export default withAuth(PersonalMenuContent);