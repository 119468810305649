/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from 'react';
import { MenuItem, Button, TextField } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import usePasswordChangeMenu from "../../../hooks/use-password-change-menu";



const PasswordChangeMenu = React.forwardRef((props, ref) => {

    const {
        isValidCurrentPassword,
        isValidNewPassword,
        isValidRepeatedPassword,
        canChange,
        changePassword,
        changeCurrentPassword,
        changeNewPassword,
        changeRepeatedPassword,
        stopPropagationForTab
    } = usePasswordChangeMenu(props);

    return (

        <MenuItem >
            <form css={css`text-align:center;`} noValidate autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                <h4>Change password</h4>
                <br />
                <TextField id="current-pwd-field"
                    error={!isValidCurrentPassword}
                    variant="outlined"
                    onKeyDown={stopPropagationForTab}
                    css={css`display:block;`}
                    key="currentPassword"
                    type="password"
                    label="Current password"
                    autoComplete="off"
                    required onChange={changeCurrentPassword} />

                <TextField id="new-pwd-field"
                    error={!isValidNewPassword}
                    variant="outlined"
                    css={css`display:block;margin:7px 0`}
                    onKeyDown={stopPropagationForTab}
                    key="newPassword"
                    type="password"
                    label="New password"
                    autoComplete="off"
                    required onChange={changeNewPassword} />

                <TextField id="repeated-pwd-field"
                    error={!isValidRepeatedPassword}
                    onKeyDown={stopPropagationForTab}
                    variant="outlined"
                    css={css`display:block;`}
                    key="repeatedPassword"
                    type="password"
                    label="Repeated password"
                    autoComplete="off"
                    required onChange={changeRepeatedPassword} />
                <br />
                <Button variant="outlined" onClick={changePassword} disabled={!canChange}>
                    <VpnKeyIcon />
                    &nbsp;
                    Change
                </Button>
            </form>
        </MenuItem>);
});

export default PasswordChangeMenu;