/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from "@material-ui/core/CircularProgress";
import useUploadControl from "../../../hooks/use-upload-control";
import withAuth from "../../../hooks/with-auth";


const UploadControl = ({ isAuthenticated }) => {

    const {
        loading,
        browseFile,
        onBrowseFile
    } = useUploadControl();

    const progress = loading ? <CircularProgress size={24} css={css`margin-left:5px;`} /> : null;
    
    return (
        isAuthenticated ?
            <form css={css`margin-left:20px`}>
                <input type="file" id="newSourceFile" name="newSourceFile" onChange={onBrowseFile} accept=".xlsx,.xls" hidden />
                <Button variant="outlined" position="relative" border={1} title="Upload new source file" onClick={browseFile}>
                    <PublishIcon />&nbsp;
                    Upload
                    {progress}
                </Button>
            </form> : null);

};

export default withAuth(UploadControl);