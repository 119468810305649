/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Colors } from "../../conventions/constants";
import PersonalMenu from "./menu/personal-menu";


const Header = () => {

    return (
        <AppBar position="static" css={css`background:${Colors.TeltonikaBlue};height:54px;`}>
            <Toolbar css={css`justify-content: space-between;`}>
                <img edge="start" css={css`padding-bottom: 12px;`} aria-label="menu" src="img/teltonika_logo.svg" />
                <PersonalMenu />
            </Toolbar>
        </AppBar>
    );
};

export default Header;