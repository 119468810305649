/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useMemo, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Header from "./header";
import Footer from "./footer";
import LookupTable from "./table";
import "../../css/footer.css";
import { Colors } from "../../conventions/constants"
import FileManagement from "./management/file-management";
import IndexRenderProps from "./index-render-props";

const Main = () => {

    const footer = useMemo(() => <Footer />, []);

    return (
        <IndexRenderProps>
            {({ rows, isFilterValid, find, onFilterChange, removeRow, removeAllRows }) =>
                <Fragment>
                    <Header />
                    <div className="content">
                        <div css={css`margin: 0 auto; padding: 12px;`}>
                            <FileManagement />
                            <div css={css`position:relative; float:right;margin:10px;padding:0 `} >
                                <TextField id="sn-search-field" variant="outlined" label="Enter part number" onChange={onFilterChange} autoComplete="off" required />
                                <Button css={css`cursor:pointer;position:absolute;right:0;top:7px;`} disabled={!isFilterValid} onClick={find}>
                                    <SearchIcon css={css`color:${isFilterValid ? Colors.GrayText : Colors.Silver}`} />
                                </Button>
                            </div>
                            <LookupTable rows={rows || []} removeRow={removeRow} removeAllRows={removeAllRows} />
                        </div>
                    </div>
                    {footer}
                </Fragment>}
        </IndexRenderProps>);
};

export default Main;