import { useState, useContext, useEffect } from 'react';
import useApi from "../../services/use-api";
import AlertContext from "../../contexts/alert/alert-context";

const IndexRenderProps = (props) => {

    const [rows, setRows] = useState([]);
    const [serialNumber, setSerialNumber] = useState("");
    const [isFilterValid, setIsFilterValid] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const { getData, isCanceled, showResponse } = useApi();

    const minFilterLengthToSearch = 14;

    const find = async () => {
        if (isFilterValid) {
            const number = serialNumber;
            await getData(`${process.env.PUBLIC_URL}/find?serialNumber=${number}`)
                .then(({ data }) => {
                    if (!data.entries || !data.entries.length) {
                        return setAlert(`No data found using Serial Number "${serialNumber}"`, 'warning', 6000);
                    }
                    data.entries.forEach(entry => entry.serialNumber = number);
                    const newSerialNumbers = data.entries.map(x => x.serialNumber);
                    return setRows(rows
                        .filter(x => x.serialNumber && !newSerialNumbers.includes(x.serialNumber))
                        .concat(data.entries));
                }).catch(ex => !isCanceled(ex) ? showResponse(ex) : null);
        }
    };

    useEffect(() => {
        find();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serialNumber, isFilterValid]);

    const onFilterChange = (event) => {
        var value = event.target.value;
        setSerialNumber(value);
        setIsFilterValid(value.length >= minFilterLengthToSearch)
    };



    const removeRow = (sn) => {
        const rowsLeft = rows.filter(x => x.serialNumber && x.serialNumber !== sn);
        return setRows(rowsLeft.length ? rowsLeft : []);
    };

    const removeAllRows = () => {
        setRows([]);
    };

    return props.children({
        serialNumber,
        rows,
        isFilterValid,
        find,
        onFilterChange,
        removeRow,
        removeAllRows
    });
};

export default IndexRenderProps;