/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { Colors } from "../../../conventions/constants"
import Row from "./row";


const LookupTable = ({ rows, removeRow, removeAllRows }) => {

  const hasNoContent = () => !rows || !rows.length;

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <Hidden xlUp>
                <TableCell />
              </Hidden>
              <TableCell align="center">SERIAL NUMBER</TableCell>
              <Hidden smDown>
                <TableCell align="center">VERSION</TableCell>
              </Hidden>
              <Hidden lgDown>
                <TableCell align="center">OEM</TableCell>
                <TableCell align="center">TYPE</TableCell>
              </Hidden>
              <Hidden mdDown>
                <TableCell align="center">VOLTAGE</TableCell>
                <TableCell align="center">BOUDRATE</TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell align="center">REMOTE DOWNLOAD</TableCell>
              </Hidden>
              <TableCell align="center" css={css`&:hover{text-decoration:underline;color:${Colors.TeltonikaBlue}}cursor:pointer`} title="remove all rows" onClick={removeAllRows}>CLEAR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (<Row key={row.serialNumber} row={row} removeRow={removeRow} />))}
          </TableBody>
        </Table>
      </TableContainer>
      <div css={css`text-align:center;padding:20px;color:#888;${hasNoContent() ? "" : "display:none"}`}>List is empty</div>
    </Fragment>
  );
};

export default LookupTable;