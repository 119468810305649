
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Colors } from "../../../conventions/constants";

const DeleteButton = ({ row, removeRow }) => {

    const remove = () => removeRow(row.serialNumber);

    return (
        <DeleteForeverIcon
            key={`r-${row.serialNumber}`}
            titleAccess="remove row"
            css={css` color: ${Colors.TeltonikaBlue}; cursor:pointer; margin:0 auto; display:block`}
            onClick={remove}>
        </DeleteForeverIcon>);
};

export default DeleteButton;