import { useState, useEffect, useContext } from 'react';
import useApi from "../services/use-api";
import { PwdRegex } from "../conventions/constants";
import AlertContext from "../contexts/alert/alert-context";



const usePasswordChangeMenu = (props) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [isValidCurrentPassword, setIsValidCurrentPassword] = useState(false);
    const [isValidNewPassword, setIsValidNewPassword] = useState(false);
    const [isValidRepeatedPassword, setIsValidRepeatedPassword] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const { setAlert } = useContext(AlertContext);
    const { postData, showResponse } = useApi();

    const changePassword = async (event) => {
        event.preventDefault();
        await postData(`${process.env.PUBLIC_URL}/change-password`, {
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
            RepeatedPassword: repeatedPassword
        }).then(response => {
            if (response.data) {
                setAlert("Password changed successfully", "success", 6000);
                props.setAnchorEl(null);
            } else {
                setAlert("Password not changed due to incorrect data", "warning", 6000);
            }
        }).catch(ex => showResponse(ex));
    }


    useEffect(() => {
        if (isValidCurrentPassword !== PwdRegex.test(currentPassword)) {
            setIsValidCurrentPassword(!isValidCurrentPassword);
        }
        if (isValidNewPassword !== PwdRegex.test(newPassword)) {
            setIsValidNewPassword(!isValidNewPassword);
        }
        if (isValidRepeatedPassword !== (PwdRegex.test(repeatedPassword) && newPassword === repeatedPassword)) {
            setIsValidRepeatedPassword(!isValidRepeatedPassword);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPassword, newPassword, repeatedPassword]);

    useEffect(() => {
        if (canChange !== isValidCurrentPassword && isValidNewPassword && isValidRepeatedPassword) {
            setCanChange(!canChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidCurrentPassword, isValidNewPassword, isValidRepeatedPassword]);


    const changeCurrentPassword = (event) => setCurrentPassword(event.target.value);
    const changeNewPassword = (event) => setNewPassword(event.target.value);
    const changeRepeatedPassword = (event) => setRepeatedPassword(event.target.value);
    const stopPropagationForTab = (event) => event.key === "Tab" ? event.stopPropagation() : true;

    return ({
        isValidCurrentPassword,
        isValidNewPassword,
        isValidRepeatedPassword,
        canChange,
        changePassword,
        changeCurrentPassword,
        changeNewPassword,
        changeRepeatedPassword,
        stopPropagationForTab
    });
};

export default usePasswordChangeMenu;