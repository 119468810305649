import React from 'react';
import './App.css';
import Routes from "./routes/index"
import AlertState from "./contexts/alert/alert-state"
import { LocalProvider } from './contexts/local-context';
import { AuthProvider } from './contexts/auth-context';
import FileUploadProvider from './contexts/file-upload-context';

const App = () => {
  return (

    <AlertState>
      <AuthProvider>
        <LocalProvider>
          <FileUploadProvider>
            <Routes />
          </FileUploadProvider>
        </LocalProvider>
      </AuthProvider>
    </AlertState>
  );
};

export default App;
